const CommonApiEndPoint = {
    item_list: '/get-item-list',
    item_wise_gst_rate_change_log_list: '/get-item-wise-gst-rate-change-log-list',
    update_quick_link: '/update-quick-links',
    get_quick_link: '/get-quick-links',
    create_year: '/create-year',
    mfg_company: '/get-mfg-company',
    mfg_company_list: '/get-mfg-company-list',
    mfg_company_filter: '/get-mfg-company-filter',
    mfg_company_details: '/get-mfg-company-details',
    batch_list: '/get-batch-list',
    unites: '/get-unites',
    get_group: '/get-group',
    get_category: '/get-category',
    get_cities: '/get-cities',
    get_states: '/get-states',
    get_import_log_list: '/get-import-log-list',
    get_import_progress_status: '/get-import-progress-status',
    get_year_list: '/get-year-list',
    get_general_setting: '/get-general-setting',
    get_bank_list: '/get-bank-list',
    get_customer_list: '/get-customer-list',
    get_vendor_list: '/get-vendor-list',
    get_op_vendor_list: '/get-op-vendor-list',
    get_gst_vendor_list: '/get-gst-vendor-list',
    get_gst_info: '/get-gst-info',
    get_ledger_group: '/get-ledger-group',
    get_ledger_list: '/get-ledger-list',
    get_menu_list: '/get-menu-list',
    get_transporter_name: '/get-transporter-name',
    get_faqs: '/get-faqs',
    get_industry: '/get-industry',
    get_latest_news: '/get-latest-news',
    get_quick_questions: '/get-quick-questions',
    get_quick_draft_list: '/get-quick-draft-list',
    get_recent_activity: '/get-recent-activity',
    get_step_completed: '/get-step-completed',
    get_email_preference_list: '/get-email-preference-list',
    get_expense_list: '/get-expense-list',
    get_expense_details: '/get-expense-details',
    get_expense_init: '/get-expense-init',
    get_nature_expense: '/get-nature-expense',
    get_nature_of_transaction_list: '/get-nature-of-transaction-list',
    get_feature_request_details: '/get-feature-request-details',
    get_feature_request_pipeline: '/get-feature-request-pipeline',
    get_feature_request_in_development: '/get-feature-request-in-developement',
    get_feature_request_done: '/get-feature-request-done',
    get_survey_list: '/get-survey-list',
    get_survey_form_question: '/get-survey-form-question',
    get_next_year_transfer_flag_data: '/get-next-year-transfer-flag-data',
    get_profile: '/get-profile',
    get_un_read_message: '/get-un-read-message',
    get_invoice_setting_details: '/get-invoice-setting-details',
    get_gstr1: '/get-gstr1',
    get_gstr1_iff: '/get-gstr-iff',
    get_gstr2: '/get-gstr2',
    get_gstr_3b: '/get-gstr-3b',
    get_gst_details: '/get-gst-details',
    get_gst_filling_status_list: '/get-gst-filling-status-list',
    get_gst_journal_voucher_init: '/get-gst-journal-voucher-init',
    get_gst_journal_voucher_account_list: '/get-gst-journal-voucher-account-list',
    get_gst_sales_jv_print: '/get-gst-sales-jv-print',
    get_gst_journal_voucher_list: '/get-gst-journal-voucher-list',
    get_op_gst_journal_voucher_list: '/get-op-gst-journal-voucher-list',
    get_journal_voucher_details: '/get-journal-voucher-details',
    get_journal_voucher_init: '/get-journal-voucher-init',
    get_jv_account_list: '/get-jv-account-list',
    get_journal_voucher_list: '/get-journal-voucher-list',
    get_op_journal_voucher_list: '/get-op-journal-voucher-list',
    get_op_journal_voucher_details: '/get-op-journal-voucher-details',
    get_stock_journal_init: '/get-stock-journal-init',
    get_stock_journal_destination_item_list: '/get-stock-journal-destination-item-list',
    get_stock_journal_details: '/get-stock-journal-details',
    get_stock_journal_list: '/get-stock-journal-list',
    get_opening_data: '/get-opening-data',
    get_sales_invoices_list: '/get-sales-invoices-list',
    get_sales_invoices_customers_list: '/get-sales-invoices-customers-list',
    get_sales_invoice_report_list: '/get-sales-invoice-report-list',
    get_sales_invoices_details: '/get-sales-invoices-details',
    get_sales_return_details: '/get-sales-return-details',
    get_sales_customer: '/get-sales-customer',
    get_purchase_bill_vendor_list: '/get-purchase-bill-vendor-list',
    get_purchase_bill_details: '/get-purchase-bill-details',
    get_op_purchase_bill_details: '/get-op-purchase-bill-details',
    get_op_purchase_bill_list: '/get-op-purchase-bill-list',
    get_sales_book_list: '/get-sales-book-list',
    get_purchase_book_list: '/get-purchase-book-list',
    get_purchase_order_details: '/get-purchase-order-details',
    get_purchase_order_list: '/get-purchase-order-list',
    get_purchase_order_init: '/get-purchase-order-init',
    get_purchase_order_print: '/get-purchase-order-print',
    get_copy_purchase_order: '/get-copy-purchase-order',
    get_purchase_bill_list: '/get-purchase-bill-list',
    get_purchase_bill_report_list: '/get-purchase-bill-report-list',
    get_purchase_return_list: '/get-purchase-return-list',
    get_purchase_return_details: '/get-purchase-return-details',
    get_purchase_vendors: '/get-purchase-vendors',
    get_tds_section_list: '/get-tds-section-list',
    get_tds_details: '/get-tds-details',
    get_tcs_section_list: '/get-tcs-section-list',
    get_tcs_details: '/get-tcs-details',
    get_countries: '/get-countries',
    get_all_notification_list: '/get-all-notification-list',
    get_notification_list: '/get-notification-list',
    get_pending_bill: '/get-pending-bill',
    get_payment_list: '/get-payment-list',
    get_payment_history: '/get-payment-history',
    get_op_payment_details: '/get-op-payment-details',
    get_op_payment_list: '/get-op-payment-list',
    get_items: '/get-items',
    additional_items: '/get-additional-service-items',
    get_vendor_subtotal: '/get-vendor-subtotal',
    get_custom_field_label: '/get-custom-field-label',
    get_bill_list: '/get-bill-list',
    get_bill_details: '/get-bill-details',
    get_bill_for_return: '/get-bill-for-return',
    get_all_ledger_list: '/get-all-ledger-list',
    get_expense_transaction_report: '/get-expense-transaction-report',
    get_batch_op_stock: '/get-batch-wise-stock-report',
    get_ledger_report: '/get-ledger-report',
    get_payable_bill_wise_report: '/get-payable-bill-wise-report',
    get_payable_vendor_wise_report: '/get-payable-vendor-wise-report',
    get_section_wise_tcs_report: '/get-section-wise-tcs-report',
    get_section_wise_tds_report: '/get-section-wise-tds-report',
    get_bulk_invoice: '/get-bulk-invoice',
    get_pending_invoice: '/get-pending-invoice',
    get_receipt_list: '/get-receipt-list',
    get_receipt_details: '/get-receipt-details',
    get_op_receipt_list: '/get-op-receipt-list',
    get_op_receipt_details: '/get-op-receipt-details',
    get_challan_item: '/get-challan-item',
    get_pending_challan: '/get-pending-challan',
    get_pending_challan_details: '/get-pending-challan-details',
    get_delivery_challan_list: '/get-delivery-challan-list',
    get_delivery_challan_init: '/get-delivery-challan-init',
    get_delivery_challan_details: '/get-delivery-challan-details',
    get_pending_quotation_items: '/get-pending-quotation-items',
    get_quotation_details: '/get-quotation-details',
    get_quotation_init: '/get-quotation-init',
    get_quotation_list: '/get-quotation-list',
    get_eway_bill_print: '/get-eway-bill-print',
    get_op_customer_list: '/get-op-customer-list',
    get_op_sales_invoices_details: '/get-op-sales-invoices-details',
    get_op_sales_invoices_list: '/get-op-sales-invoices-list',
    get_copy_invoice: '/get-copy-invoice',
    get_customer_subtotal: '/get-customer-subtotal',
    get_receipt_history: '/get-receipt-history',
    get_invoice_list: '/get-invoice-list',
    get_invoice_for_return: '/get-invoice-for-return',
    get_invoice_details: '/get-invoice-details',
    get_sales_return_list: '/get-sales-return-list',
    get_statutory_info: '/get-statutory-info',
    get_excise_details: '/get-excise-details',
    get_vat_details: '/get-vat-details',
    get_stock_adj_items: '/get-stock-adj-items',
    get_stock_adj_details: '/get-stock-adj-details',
    get_stock_adj_init: '/get-stock-adj-init',
    get_stock_adj_list: '/get-stock-adj-list',
    get_customer_address_list: '/get-customer-address-list',
    get_payment_detail: '/get-payment-detail',
    get_subscription_list: '/get-subscription-list',
    get_company_plan_list: '/get-company-plan-list',
    get_transporter_details: '/get-transporter-details',
    get_transporter_list: '/get-transporter-list',
    get_group_details: '/group-details',
    get_group_list: '/get-group-list',
    get_category_details: '/category-details',
    get_category_list: '/get-category-list',
    get_payment_details: '/get-payment-details',
    get_unit_list: '/get-unit-list',
    get_staff_list: '/get-staff-list',
    get_assign_rights: '/get-assign-rights',
    get_collaboration_list: '/get-collaboration-list',
    get_series_list: '/get-series-list',
    get_series_details: '/get-series-details',
    user_details: '/user-details',
    renew_token: '/renew-token',
    company_list: '/company-list',
    update_last_selected_company: '/update-last-selected-company',
    update_last_selected_year: '/update-last-selected-year',
    check_current_plan: '/check-current-plan',
    charts_of_account_group_list: '/charts-of-account-group-list',
    sent_invitations: '/sent-invitations',
    module_list: '/module-list',
    verify_password: '/verify-password',
    receive_invitations: '/receive-invitations',
    unit_details: '/unit-details',
    subscription_print: '/subscription-print',
    used_history: '/used-history',
    set_default_billing_address: '/set-default-billing-address',
    discount_code_impression: '/discount-code-impression',
    discount_code_apply: '/discount-code-apply',
    check_payment_method: '/check-payment-method',
    pincode_verify: '/pincode-verify',
    sales_return_init: '/sales-return-init',
    generate_einvoice: '/generate-einvoice',
    generate_einvoice_report: '/generate-einvoice-report',
    generate_eway_bill: '/generate-eway-bill',
    generate_eway_bill_report: '/generate-eway-bill-report',
    pending_quotation_for_sales: '/pending-quotation-for-sales',
    check_customer_credit_limit: '/check-customer-credit-limit',
    sales_invoice_init: '/sales-invoice-init',
    sales_invoice_print: '/sales-invoice-print',
    challan_print: '/challan-print',
    receipt_voucher_init: '/receipt-voucher-init',
    item_wise_tax_report: '/item-wise-tax-report',
    hsn_sac_wise_tax_report: '/hsn-sac-wise-tax-report',
    receivable_report_invoice_wise: '/receivable-report-invoice-wise',
    receivable_report_customer_wise: '/receivable-report-customer-wise',
    account_receivable_aging_report: '/account-receivable-aging-report',
    payment_received_report: '/receivable-report-payment-wise',
    account_receivable_aging_detail_report: '/account-receivable-aging-detail-report',
    vendor_wise_outstanding_report: '/vendor-wise-outstanding-report',
    purchase_payment_report: '/purchase-payment-report',
    receivable_report_customer_invoice_wise: '/receivable-report-customer-invoice-wise',
    receivable_report_customer_invoice_wise_export: '/receivable-report-customer-invoice-wise-export',
    receivable_report_customer_invoice_wise_details: '/receivable-report-customer-invoice-wise-details',
    customer_wise_outstanding_report: '/customer-wise-outstanding-report',
    fetch_staff_list: '/fetch-staff-list',
    company_details: '/company-details',
    growth_pluses: '/growth-pluses',
    revenue_inflow: '/revenue-inflow',
    revenue_management: '/revenue-management',
    revenue_projection: '/revenue-projection',
    sales_prchase: '/sales-prchase',
    statistics_details: '/statistics-details',
    dashboard_init: '/dashboard-init',
    sales_analytics: '/sales-analytics',
    set_primary_email: '/set-primary-email',
    send_email_verification: '/send-email-verification',
    verify_preference_email: '/verify-preference-email',
    cancel_einvoice_report: '/cancel-einvoice-report',
    cancel_eway_bill_report: '/cancel-eway-bill-report',
    canceld_sales_return_einvoice: '/canceld-sales-return-einvoice',
    canceld_sales_return_eway_bill: '/canceld-sales-return-eway-bill',
    gst_purchase_with_hsn: '/gst-purchase-with-hsn',
    gst_sales_with_hsn: '/gst-sales-with-hsn',
    hsn_sales_summary: '/hsn-sales-summary',
    generate_gstr1_amendment: '/generate-gstr1-amendment',
    generate_otp_request: '/generate-otp-request',
    generate_otp: '/generate-otp',
    generate_auth_token: '/generate-auth-token',
    gstr2a_reconciliation_init: '/gstr2a-reconciliation-init',
    download_gstr2a_report: '/download-gstr2a-report',
    generate_gstr_2a: '/generate-gstr-2a',
    item_details: '/item-details',
    get_op_gst_journal_voucher_details: 'get-op-gst-journal-voucher-details',
    get_gst_journal_voucher_details: 'get-gst-journal-voucher-details',
    ledger_details: '/ledger-details',
    payment_voucher_init: '/payment-voucher-init',
    purchase_return_init: '/purchase-return-init',
    verify_user: '/verify-user',
    dash_expected_payable: '/dash-expected-payable',
    dash_expected_receivable: '/dash-expected-receivable',
    dash_long_pending_receipt: '/dash-long-pending-receipt',
    dash_bank_balance: '/dash-bank-balance',
    dash_total_collected: '/dash-total-collected',
    dash_cashinhand: '/dash-cashinhand',
    dash_payable_report: '/dash-payable-report',
    dash_total_purchase: '/dash-total-purchase',
    dash_receivable_report: '/dash-receivable-report',
    dash_total_sales: '/dash-total-sales',
    dash_unavoidable_payable: '/dash-unavoidable-payable',
    generate_balance_sheet_report: '/generate-balance-sheet-report',
    generate_profit_loss_report: '/generate-profit-loss-report',
    purchase_return_item_wise: '/purchase-return-item-wise',
    purchase_return_summary: '/purchase-return-summary',
    sales_return_item_wise: '/sales-return-item-wise',
    sales_return_summary: '/sales-return-summary',
    pending_delivery_challan_report: '/pending-delivery-challan-report',
    adjusted_costing_report: '/adjusted-costing-report',
    customer_wise_item_report: '/customer-wise-item-report',
    date_customer_wise_item_report: '/date-customer-wise-item-report',
    date_vendor_wise_item_report: '/date-vendor-wise-item-report',
    vendor_wise_item_report: '/vendor-wise-item-report',
    item_sales_summary: '/item-sales-summary',
    item_stock_ledger_report: '/item-stock-ledger-report',
    item_low_stock_summary: '/item-low-stock-summary',
    stock_aging_report: '/stock-aging-report',
    stock_summary: '/stock-summary',
    payable_report_vendor_bill_wise: '/payable-report-vendor-bill-wise',
    payable_report_vendor_bill_wise_export: '/payable-report-vendor-bill-wise-export',
    payable_report_vendor_bill_wise_details: '/payable-report-vendor-bill-wise-details',
    update_notification_status: '/update-notification-status',
    remove_notification: '/remove-notification',
    update_notify_count: '/update-notification-count',
    update_item_negative_qty: '/update-item-negative-qty',
    cancel_einvoice: '/canceled-einvoice',
    update_email_preference: '/update-email-preference',
    create_email_preference: '/create-email-preference',
    cancel_eway_bill: '/canceled-eway-bill',
    delete_chart_account: '/delete-charts-of-account-group',
    create_chart_account: '/create-charts-of-account-group',
    update_chart_account: '/update-charts-of-account-group',
    set_unread_msg: '/set-un-read-messages',
    update_unread_msg: '/update-un-read-messages',
    update_conversation: '/update-conversation',
    chat_file_upload: '/chat-file-upload',
    get_single_user: '/get-single-user',
    delete_conversation: '/delete-conversation',
    get_user_chat_list: '/get-user-chat-list',
    get_messages: '/get-messages',
    send_messages: '/send-messages',
    add_conversation: '/add-conversation',
    create_company: '/create-company',
    insert_pincode: '/insert-pincode',
    set_gst_details: '/set-gst-details?',
    check_company_name: '/check-company-name',
    update_company: '/update-company',
    payment_activity_logs: '/payment-activity-logs',
    activity_log_list: '/activity-log-list',
    next_year_transfer: '/next-year-transfer',
    next_year_stock_transfer: '/next-year-stock-transfer',
    import_log_clear_dashboard: '/import-log-clear-dashboard',
    update_import_progress: '/update-import-progress',
    submit_quick_questions: '/submit-quick-questions',
    create_quick_draft: '/create-quick-draft',
    delete_quick_draft: '/delete-quick-draft',
    add_user_rating: '/add-user-rating',
    update_step_id: '/update-step-id',
    import_ledger: '/import-ledger',
    import_op_purchase: '/import-op-purchase',
    import_op_sales: '/import-op-sales',
    delete_email_preference: '/delete-email-preference',
    update_expense: '/update-expense',
    create_expense: '/create-expense',
    delete_expense: '/delete-expense',
    set_next_year_transfer: '/set-next-year-transfer',
    set_next_year_stock_transfer: '/set-next-year-stock-transfer',
    feature_request_comment: '/feature-request-comment',
    save_feature_request_upvote: '/save-feature-request-upvote',
    get_comment_list: '/get-comment-list',
    create_feature_req: '/create-feature-request',
    get_feature_request: '/get-feature-request',
    save_product_survey_question_answer: '/save-product-survey-question-answer',
    save_survey_impression: '/save-survey-impression',
    general_setting: '/general-setting',
    update_next_year_transfer_flag: '/update-next-year-transfer-flag',
    update_gst_filling_status: '/update-gst-filling-status',
    get_b2b_reconciliation_data: '/get-b2b-reconciliation-data',
    set_invoices_setting: '/set-invoices-setting',
    update_item: '/update-item',
    create_item: '/create-item',
    delete_item: '/delete-item',
    update_gst_journal_voucher: '/update-gst-journal-voucher',
    create_gst_journal_voucher: '/create-gst-journal-voucher',
    delete_gst_journal_voucher: '/delete-gst-journal-voucher',
    update_journal_voucher: '/update-journal-voucher',
    create_journal_voucher: '/create-journal-voucher',
    delete_journal_voucher: '/delete-journal-voucher',
    update_stock_journal: '/update-stock-journal',
    create_stock_journal: '/create-stock-journal',
    delete_stock_journal: '/delete-stock-journal',
    set_opening_data: '/set-opening-data',
    update_ledger: '/update-ledger',
    create_ledger: '/create-ledger',
    delete_ledger: '/delete-ledger',
    update_mfg_company: '/update-mfg-company',
    create_mfg_company: '/create-mfg-company',
    delete_mfg_company: '/delete-mfg-company',
    update_conversation: '/update-conversation',
    update_op_payment: '/update-op-payment',
    update_payment: '/update-payment',
    create_payment: '/create-payment',
    delete_payment: '/delete-payment',
    update_op_purchase_bill: '/update-op-purchase-bill',
    create_op_purchase_bill: '/create-op-purchase-bill',
    delete_op_purchase_bill: '/delete-op-purchase-bill',
    update_purchase_bill: '/update-purchase-bill',
    create_purchase_bill: '/create-purchase-bill',
    delete_purchase_bill: '/delete-purchase-bill',
    update_purchase_order: '/update-purchase-order',
    create_purchase_order: '/create-purchase-order',
    delete_purchase_order: '/delete-purchase-order',
    po_approval: '/po-approval',
    update_purchase_return: '/update-purchase-return',
    create_purchase_return: '/create-purchase-return',
    delete_purchase_return: '/delete-purchase-return',
    generate_login_otp: '/generate-login-otp',
    generate_user_otp: '/generate-user-otp',
    verify_user_otp: '/verify-user-otp',
    update_bulk_invoice: '/update-bulk-invoice',
    update_op_receipt: '/update-op-receipt',
    update_receipt: '/update-receipt',
    create_receipt: '/create-receipt',
    delete_receipt: '/delete-receipt',
    update_delivery_challan: '/update-delivery-challan',
    create_delivery_challan: '/create-delivery-challan',
    delete_delivery_challan: '/delete-delivery-challan',
    update_op_sales_invoice: '/update-op-sales-invoice',
    create_op_sales_invoice: '/create-op-sales-invoice',
    delete_op_sales_invoice: '/delete-op-sales-invoices',
    update_sales_invoice: '/update-sales-invoice',
    create_sales_invoice: '/create-sales-invoices',
    delete_sales_invoice: '/delete-sales-invoices',
    update_quotation: '/update-quotation',
    create_quotation: '/create-quotation',
    delete_quotation: '/delete-quotation',
    update_sales_return: '/update-sales-return',
    create_sales_return: '/create-sales-return',
    delete_sales_return: '/delete-sales-return',
    set_excise_details: '/set-excise-details',
    set_gst_details: '/set-gst-details',
    set_tcs_details: '/set-tcs-details',
    set_tds_details: '/set-tds-details',
    set_vat_details: '/set-vat-details',
    statutory_info: '/statutory-info',
    update_stock_adj: '/update-stock-adj',
    create_stock_adj: '/create-stock-adj',
    delete_stock_adj: '/delete-stock-adj',
    update_customer_address: '/update-customer-address',
    create_customer_address: '/create-customer-address',
    delete_customer_address: '/delete-customer-address',
    continue_payment: '/continue-payment',
    subscription: '/subscription',
    update_transporter: '/update-transporter',
    create_transporter: '/create-transporter',
    delete_transporter: '/delete-transporter',
    update_group: '/update-group',
    create_group: '/create-group',
    delete_group: '/delete-group',
    update_category: '/update-category',
    create_category: '/create-category',
    delete_category: '/delete-category',
    update_unit: '/update-unit',
    create_unit: '/create-unit',
    delete_unit: '/delete-unit',
    accept_invitation: '/accept-invitation',
    decline_invitation: '/decline-invitation',
    update_profile: '/update-profile',
    change_email_mobile: '/change-email-mobile',
    change_password: '/change-password',
    update_module_list: '/update-module-list',
    company_permission: '/company-permission',
    delete_invitation: '/delete-invitations',
    transfer_ownership: '/transfer-ownership',
    verify_company: '/verify-company',
    filter_list: '/get-filter-list',
    delete_filter: '/delete-filter',
    create_filter: '/create-filter',
    update_filter: '/update-filter',
    get_filter_detail: '/get-filter-details',
    create_series: '/create-series',
    update_series: '/update-series',
    delete_series: '/delete-series',
    get_company_user: '/get-company-all-user',
    get_all_cities: '/get-all-cities',
    get_all_states: '/get-all-states',
    without_verify_gstin: '/without-verify-gstin',
    get_incomplete_user_data: '/get-incomplete-user-data',
    check_quick_gst: '/check-quick-gst',
    get_my_company_list: '/get-my-company-list',
    check_all_item_active: '/check-all-item-active',
    recover_sales_invoice: '/recover-sales-invoice',
    update_aff_visit_count: '/update-aff-visit-count',
    update_print_settings: '/update-print-settings',
    get_print_settings: '/get-print-settings',
    receipt_print: '/receipt-print',
    payment_print: '/payment-print',
    demo_register: '/demo-register',
    get_book_series: '/get-book-series',
    share_invoice_whatsapp: '/share-invoice-whatsapp',
    get_customer_pending_invoice_report: '/get-customer-pending-invoice-report',
    return_generate_eway_bill: '/return-generate-eway-bill',
    return_generate_einvoice: '/return-generate-einvoice',
    transaction_attachment: '/transaction-attachment',
    import_item: '/import-item',
    import_purchase: '/import-purchase',
    import_sales: '/import-sales',
    get_return_eway_bill_print: '/get-return-eway-bill-print',
    get_daybook: '/daybook-report',
    generate_trial_balance_report: '/generate-trial-balance-report',
    sales_register_detail_report: '/sales-register-detail-report',
    purchase_register_detail_report: '/purchase-register-detail-report',
    log_in: '/log-in',
    clear_all_notification: '/clear-all-notification',
    update_dashboard_amt: '/update-dashboard-amt',
    update_request_status: '/update-request-status',
    incomplete_user_profile_status: '/incomplete-user-profile-status',
    get_bulk_einvoice_process: '/get-bulk-einvoice-process',
    get_return_bulk_einvoice_process: '/get-return-bulk-einvoice-process',
    get_munim_connector_request: '/get-munim-connector-request',
    stock_register: '/get-stock-register',
    get_all_item_list: '/get-all-item-list',
    get_item_rate_card: '/get-item-rate-card',
    item_price_history: '/item-price-history',
    get_brs_bank_list: '/get-brs-bank-list',
    get_bank_import_history: '/get-bank-import-history',
    import_bank: '/import-bank',
    get_bank_statements: '/get-bank-statements',
    create_brs_adjustment: '/create-brs-adjustment',
    get_brs_import_progress: '/get-brs-import-progress',
    get_default_printer: '/get-default-printer',
    check_company_exist: '/check-company-exist',
    update_whatsapp_status: '/update-whatsapp-status',
    wa_activity: '/login-activity-logs',
    get_wa_login: '/get-whatsapp-login',
    resync_statements: '/resync-statements',
    error_handling: '/error-handling',
    verify_bill_number: '/verify-bill-number',
    get_barcode_list: '/get-barcode-list',
    update_barcode: '/update-barcode',
    assign_and_pending: '/get-assign-pending-barcode',
    update_pending_barcode: '/update-pending-barcode',
    get_barcode_string: '/get-barcode-string',
    get_item_barcode_list: '/get-item-barcode-list',
    barcode_preview: '/barcode-preview',
    delete_file: '/delete-file',
    purchase_bill_print: '/purchase-bill-print',
    generate_dc_eway_bill: '/generate-delivery-challan-eway-bill',
    get_account_transaction: '/get-account-transaction',
    get_lut_bond_log_list:'/get-lut-bond-log-list',
    purchase_bill_print: '/purchase-bill-print',
    get_contra_entry_list: '/get-contra-entry-list',
    get_contra_entry_init: '/get-contra-entry-init',
    get_contra_account_list: '/get-contra-account-list',
    get_contra_entry_details: '/get-contra-entry-details',
    create_contra_entry: '/create-contra-entry',
    update_contra_entry: '/update-contra-entry',
    delete_contra_entry: '/delete-contra-entry',
    update_banner_status: '/update-banner-status',
    create_ship_address: '/create-ship-address',
    get_ship_address_list: '/get-ship-address-list',
    set_default_ship_address : '/set-default-ship-address',
    delete_ship_address : '/delete-ship-address',
    update_ship_address : '/update-ship-address',
    ship_address_details : '/ship-address-details',
    cancel_dc_eway_bill: '/canceld-delivery-challan-eway-bill',
    get_dc_eway_bill_print: '/get-delivery-challan-eway-bill-print',
    purchase_order_pending: '/get-pending-po-report-list',
    purchase_bill_init: '/purchase-bill-init',
    get_currency_decimal_list: '/get-currency-decimal-list',
    update_currency_decimal: '/update-currency-decimal'
}
export default CommonApiEndPoint

export const SampleFileName = {
    item_sample: 'item_sample.xlsx',
    account_sample: 'account_sample.xlsx',
    purchase_sample: 'purchase_sample.xlsx',
    op_purchase_sample: 'op_purchase_sample.xlsx',
    sales_sample: 'sales_sample.xlsx',
    op_sales_sample: 'op_sales_sample.xlsx',
    bank_reconciliation_sample: 'bank_reconciliation_sample.xlsx'
}
const ValidationMessage = {
    file_size: 'Please check file size, we allow up to 2 mb sized file only.',
    is_require: 'Is required!',
    to_short: 'Too short!',
    to_long: 'Too long!',
    mn_not_valid: 'Mobile number is not valid!',
    pin_digit: 'Pincode must be 6 digit!',
    valid_pincode: 'Please enter valid pincode!',
    valid_email: 'Must be a valid email!',
    valid_password: 'Your password must be at least 6 characters. A recommended password is a combination of uppercase letters, lowercase letters, numbers, and symbols (!$@%).',
    signup_valid_password: 'Please follow password pattern!',
    valid_till: 'Quotation date is not greater than valid till date!',
    small_letter: 'Only alphabets is allow!',
    disc_in_percentage: 'Invalid percentage!',
    disc_percentage: 'Invalid %!',
    mrp_valid: 'Invalid mrp!',
    disc_in_amount: 'Discount amount should not be more then goods/service value!',
    disc_amount: 'Amount of discount and payment must be equal to open balance!',
    tds_amount: 'Amount of tds and receipt must be equal to open balance!',
    otp_min_max: 'OTP is not valid!',
    pan_valid: 'Please enter valid PAN no.!',
    phn_valid: 'Please enter valid phone number!',
    fax_valid: 'Please enter valid fax number!',
    website_valid: 'URL is not valid!',
    gst_valid: 'Please enter valid GSTIN!',
    transporter_valid: 'Please enter valid transporter id!',
    pass_char: 'Password must be 6 to 16 character!',
    confirm_pass: 'Confirm password must match with password!',
    new_confirm_pass: 'New password and confirm passwords should be match!',
    future_date_allow: 'Future date is not allowed!',
    pd_past_date: 'Past date is not allowed!',
    vs_isx_valid: 'Please enter valid TIN!',
    rate_per: 'Rate must be between 0 to 100 percent (%)!',
    credit_period: 'Credit period days between 1 to 180!',
    fin_date: 'Please select a date of the current financial year!',
    prefix: 'Invalid prefix, Do not allowed to enter special characters (like / or -) in initial of prefix!',
    bill_prefix: 'Invalid bill number, Do not allowed to enter special characters (like @, $ or *) in initial of bill number!',
    len_zero: 'Length of leading zero must be 0-3!',
    gst_applicable_date_valid: 'GST applicable date must be after company establishment date!',
    item_code_valid: 'Special character not allowed!',
    hsn_sac_min: 'At least 4 digits are required!',
    barcode_min: 'At least 4 digits are required!',
    ifsc_cap: 'IFSC code must be valid!',
    bill_no_prefix: 'Bill no should not start with zero!',
    invoice_prefix_valid: 'Invoice No. should not start with zero!',
    eway_applicable_date: 'Please enter E-Way applicable date!',
    lut_bond_no_req: 'Please enter LUT/Bond no.!',
    lut_bond_no_max: 'Maximum 20 character allowed!',
    lut_bond_from_date: 'Please enter LUT/Bond validity from date!',
    lut_bond_to_date: 'Please enter LUT/Bond validity to date!',
    vat_applicable_date_valid: 'VAT registration date must be after company establishment date!',
    outstanding_amount_valid: 'Outstanding amount 0 is not allow!',
    module_permission_valid: 'Please give at least one module permission to user!',
    otp_req: 'Please enter OTP!',
    op_stock_value_valid: 'Please enter more than zero value in op stock & quantity',
    op_stock_qty: 'Qty should be greater than 0',
    op_stock_value: 'Value should be greater than 0',
    prefix_valid: 'No. should not start with zero!',
    eway_pincode: 'Please enter Pincode, while creating the e-way',
    suffix: 'You have entered an invalid special character, (Here allowed only special characters / or - ).',
    payment_amount: 'Paymment amount should be greater than 0.',
    items_valid: 'Comma is not allow in item name!',
    valid_upiid: 'Please enter valid UPI id!',
    file_type: 'Please Select this .jpeg/.jpg/.png/.pdf/.doc/.docx/.xlsx File',
    back_date: 'You have no permission to do a backdated entry',
    company_address_line: 'Please ensure that address 1 has up to 100 characters.',
    item_name_to_long: 'Please ensure that item name has up to 250 characters.',
    valid_date: 'Please select valid date!'
}
export default ValidationMessage
export const notifyMessage = {
    cancel_eway_einvoice_message: 'Please select reason of cancelation!',
    cancel_date: 'Please select cancel date!',
    total_amount_not_be_zero: 'Total amount should not be zero!',
    // nature_of_expense_req: 'Please select at least one nature of expense!',
    invoice_no_valid: 'Invoice number must be only 16 digits!',
    product_is_req: 'Please select at least one product!',
    account_req_for_primary_account: 'Please select account for primary data!',
    primary_account_req: 'Please select primary account!',
    credit_and_debit_total_same: 'Total of credit and debit must be same!',
    consumption_production_total: 'Total amount of consumption and production should not be zero!',
    more_than_paid_amount: 'Do not allow more than paid amount!',
    amount_paid_not_be_zero: 'Total amount paid should not be zero!',
    valid_date: 'Please select valid date!',
    date_btn_establish_financial: 'Please select date between establish date to financial year end date!',
    bill_to_date_req: 'Please select bill to date!',
    bill_from_date_req: 'Please select bill from date!',
    quantity_of_product_req: 'Please add at least one quantity of product!',
    start_end_date_valid: 'Start date should be less than end date!',
    more_than_received_amount: 'Do not allow more than received amount!',
    received_amount_not_be_zero: 'Total received amount should not be zero!',
    invoice_to_date_req: 'Please select invoice to date!',
    invoice_from_date_req: 'Please select invoice from date!',
    export_sale_book_not_valid: 'Do not  select Exports Sales book, because party type is SEZ!',
    einvoice_bill_option_req: 'Please at least one option select!',
    delete_row_shortcut_work_on_challan: 'Attention, If Challan item selected, delete row (shortcut) not working!',
    vat_reg_date_valid: 'VAT registration date is not less then company establishment date!',
    cst_reg_date_valid: 'CST registration date is not less then company establishment date!',
    password_req: 'Please enter your password!',
    tcs_section_req: 'Please select any one TCS section!',
    login_signup_req: 'Please login/signup to perform this action!',
    return_without_invoice: 'You have not select any invoice item!',
    return_without_bill: 'You have not select any bill item!',
    email_excel: 'Exported data will send to your registered email shortly!',
    enter_valid_otp: 'Please enter valid OTP!',
    transaction_receipt_req: 'Please upload receipt before proceed!',
    otp_error_message: 'You are not allowed to save changes, first get OTP and complete the verification process!',
    select_product_service: 'Please select at least one goods/service.',
    select_nature_of_expense: 'Please select at least one nature of expense.',
    select_account: 'Please select at least one account',
    total_amt_to_long: 'Entered amount is too big. (Allowed only max 10 digits.)',
    total_qty_to_long: 'Entered quantity is too big. (Allowed only max 10 digits.)',
    create_new_stock_no: 'New Batch/Lot no. will be create.',
    series_length: 'Document number length should not be more than 16 digits.',
    check_customer_create_inv_from_quo: 'Customer is inactive, you cannot generate invoice using this quotation.',
    check_customer_create_challan_from_quo: 'Customer is inactive, you cannot generate challan using this quotation.',
    check_customer_cancel_quo: 'customer is inactive, you cannot cancel this quotation.',
    check_customer_close_quo: 'customer is inactive, you cannot close this quotation.',
    check_items_cancel_quo: 'All items are inactive, we cannot allow you to cancel this quotation.',
    check_items_close_quo: 'All items are inactive, we cannot allow you to close this quotation.',
    check_vendor_create_PB: 'Vendor is inactive, you cannot generate bill using this purchase order.',
    check_items_create_SI_from_quo: 'All items are inactive, we cannot allow you to create a sales invoice using this quotation.',
    check_items_create_challan_from_quo: 'All items are inactive, we cannot allow you to create a challan using this quotation.',
    create_PB_from_PO: 'All items are inactive, we cannot allow you to create a purchase bill using this purchase order.',
    check_cutomer_copy_invoice: 'Customer is inactive, you cannot generate copy invoice using this sales invoice.',
    check_cutomer_copy_receipt: 'Customer is inactive, you cannot generate receipt using this customer.',
    check_cutomer_copy_receipt_invoice: 'Customer is inactive, you cannot generate receipt using this invoice.',
    check_cutomer_copy_vendor: 'Vendor is inactive, you cannot generate payment using this vendor.',
    check_cutomer_copy_vendor_bill: 'Vendor is inactive, you cannot generate payment using this bill.',
    check_items_copy_invoice: 'All items are inactive, we cannot allow you to copy invoice using this sales invoice.',
    check_customer_create_SI_from_challan: 'Customer is inactive, you cannot generate sales invoice using this challan.',
    check_items_create_SI_from_challan: 'All items are inactive in this challan, we cannot allow you to create a sales invoice using this challan.',
    subscription_expire_0: 'Your current subscription has expired. Please renew to continue operation.',
    subscription_expire_1: 'Your current subscription has expired. Please contact your owner for renewal.',
    check_customer_create_receipt_from_SI: 'Customer is inactive, you cannot generate receipt using this sales invoice.',
    check_customer_sales_return_from_SI: 'Customer is inactive, you cannot generate sales return using this sales invoice.',
    check_customer_cancel_from_SI: 'Customer is inactive, you cannot cancel this sales invoice.',
    check_customer_file_attachment: 'Customer is inactive, you cannot file attachment this sales invoice.',
    check_items_create_receipt_from_SI: 'All items are inactive, we cannot allow you to generate receipt using this sales invoice.',
    check_items_create_sales_return_from_SI: 'All items are inactive, we cannot allow you to generate sales return using this sales invoice.',
    check_items_cancel_from_SI: 'All items are inactive, we cannot allow you to cancel this sales invoice',
    check_items_file_attachment_from_SI: 'All items are inactive, we cannot allow you to file attachment this sales invoice.',
    check_customer_cancel_sales_return: 'Customer is inactive, you cannot cancel this sales return.',
    check_items_cancel_sales_return: 'All items are inactive, we cannot allow you to cancel this sales return.',
    trial_expire_0: 'Your free trial period is over. Buy a subscription and enjoy your accounting operations.',
    trial_expire_1: 'Your free trial period is over. Contact to your owner for Buy a subscription.',
    check_vendor_create_bill_from_PO: 'Vendor is inactive, you cannot generate purchase bill using this purchase order.',
    check_vendor_approved_from_PO: 'Vendor is inactive, you cannot approved this purchase order.',
    check_vendor_rejected_from_PO: 'Vendor is inactive, you cannot rejected this purchase order.',
    check_vendor_cancel_from_PO: 'Vendor is inactive, you cannot cancel this purchase order.',
    check_vendor_copyPO_from_PO: 'Vendor is inactive, you cannot copy PO this purchase order.',
    check_vendor_manualclose_from_PO: 'Vendor is inactive, you cannot manual close this purchase order.',
    check_vendor_delete_from_PO: 'Vendor is inactive, you cannot delete this purchase order.',
    check_items_create_bill_from_PO: 'All items are inactive, we cannot allow you to generate purchase bill using this purchase order.',
    check_items_approved_from_PO: 'All items are inactive, we cannot allow you to approved this purchase order.',
    check_items_rejected_from_PO: 'All items are inactive, we cannot allow you to rejected this purchase order.',
    check_items_cancel_from_PO: 'All items are inactive, we cannot allow you to cancel this purchase order.',
    check_items_copyPO_from_PO: 'All items are inactive, we cannot allow you to copy PO this purchase order.',
    check_items_manualclose_from_PO: 'All items are inactive, we cannot allow you to manual close this purchase order.',
    check_items_delete_from_PO: 'All items are inactive, we cannot allow you to delete this purchase order.',
    check_vendor_create_payment_from_PB: 'Vendor is inactive, you cannot generate payment using this purchase bill.',
    check_vendor_purchase_return_from_PB: 'Vendor is inactive, you cannot generate purchase return using this purchase bill.',
    check_vendor_file_attachment: 'Vendor is inactive, you cannot file attachment this purchase bill.',
    check_items_create_payment_from_PB: 'All items are inactive, we cannot allow you to generate payment using this purchase bill.',
    check_items_purchase_return_from_PB: 'All items are inactive, we cannot allow you to generate purchase return using this purchase bill.',
    check_items_file_attachment_from_PB: 'All items are inactive, we cannot allow you to file attachment this purchase bill.',
    check_vendor_cancel_purchase_return: 'Vendor is inactive, you cannot cancel this purchase return.',
    check_items_cancel_purchase_return: 'All items are inactive, we cannot allow you to cancel this purchase return.',
    check_vendor_cancel_GJV: 'Vendor is inactive, you cannot cancel this GST journal voucher.',
    expense_paid_amount_message: 'Paid amount should not be greater than expenses total amount.',
    generate_eway_from_oth_country: 'Shipping Party must be within india for generate Eway bill, so try again with other party!',
    whatsapp_contact_not_add: 'You cannot share a report on WhatsApp for this account, Reason: Mobile number not added for this account.',
    check_sales_payment_received_amt: 'Total Payment Received amount should not be greater than invoice total amount.',
    upload_file_two_Mb: 'Upload file size up to 2 MB',
    upload_file_hundred_kB: 'Upload file size up to 100 kB',
    upload_watermark: 'Please upload watermark image.',
    owner_invite_error: 'You can’t invite owner so try with a different mobile number!',
    support_token_copeied_clipboard: 'Support token is copied to clipboard.',
    default_entry_date: 'Your default entry date set as today !',
    select_start_date_current_finacial_year: 'Please select start date of the current financial year!',
    select_end_date_current_finacial_year: 'Please select end date of the current financial year!',
    file_size_too_large: 'File size is too large.',
    space_not_allowed: 'Space is not allow',
    pincode_not_matched_with_statecode: "The pincode does not match with state code of Company's Gstin. Please try with another pincode.",
    server_counter_unknown_error: 'Server counter unknown error.',
    no_question_found: 'no question found',
    invalid_GST_username: 'Invalid GST username, Please update GST username in Statutory Info!',
    accept_terms_condition: 'Please accept for terms & conditions!',
    select_atleast_one_item: 'Please adjust at least one item before save!',
    subscription: 'Please subscribe, to perform this operation!',
    user_limit_exeeds_add_staff: 'Your user limit has been exceeded, do you want to add more user then click here',
    not_allowed_add_demo_staff: 'You are not allowed to perform this operation, because this is a demo account. click here to start with a live account.',
    same_account_for_posting: 'You have selected the same account for posting. Please correct it.',
    pincode_match_with_state: 'The pincode does not match with state code of Company’s Gstin. Please try with another pincode.',
    barcode_atleast_four_numbers: 'The barcode must have a minimum length of 4 numbers.',
    item_already_exist: 'Item Already Exist',
    fill_sticker_quantity: 'Please enter the sticker quantity here.',
    barcode_error_msg: 'No items found with this barcode!',
    wa_success_login: "You are successfully logged into WA.",
    wa_success_logout: "You are successfully logged out from WA.",
    wa_si_sent: "Sales invoice sent successfully on WA: {mobile_number}.",
    same_account_for_posting: 'You have selected the same account for posting. Please correct it.',
    pincode_match_with_state: 'The pincode does not match with state code of Company’s Gstin. Please try with another pincode.',
    barcode_error_msg: 'No items found with this barcode!',
    wa_logout_info: "<b>Do you want link a device again?</b><br/>You need to first logout from device (mobile), which you had used for send invoices.  Once you logged out, Reload the desktop app.",
    check_items_file_attachment_from_DC: 'All items are inactive, we cannot allow you to file attachment this delivery challan.',
    check_customer_file_attachment_from_DC: 'Customer is inactive, you cannot file attachment this delivery challan.',
    check_purchase_payment_paid_amt: 'Total Paid amount should not be greater than bill total amount.',
    wa_timeout: "Timeout or connection error! <br /><u style='color:blue'>click here for possible reasons.</u>",
    invalid_file_type: 'Invalid file type. The type of the uploaded file should be .jpeg/.jpg/.png/.bmp',
    thermal_column_width_zero : 'Enter more than 0 width',
    thermal_column_detail_blank : 'please enter your column in detail',
    thermal_columns_width_hundred_plus : 'Column width is more then 100 percentage',
    thermal_column_width_detail_require : 'Column width should be more then zero!',
    at_least_one_details_labels_required:'Please select at least one option in details labels.'

}
export const popUpMessage = {
    discard_title: 'Unsaved changes!',
    discard_content: 'If you discard changes, you’ll delete any edits you made since you last saved.',
    cancel_content: 'Leaving this page will delete all unsaved changes.'
}

export const activityMessage = {
    wa_login: "Logged in successfully from Machine: ",
    wa_logout: "Logged out successfully from Machine: "
}
export const tooltipMessage = {
    quick_link: 'Quick Links',
    missing_feature: 'Missing feature',
    need_help: 'Do you need help?',
    global_search: 'Search anything like modules, reports and settings.',
    keyboard_shortcuts: 'Keyboard Shortcuts',
    set_entry_date: 'Set default entry date by pressing F2 key. This date will show on every entry window. So it will help user to do quick entry and do not need to change date again and again when doing past date entry.',
    notifications: 'Notifications',
    support_token: 'What is Support Token?',
    support_token_disc: 'Suppose, user need any help from munim support team. User just need click on copy button and share token with munim support team, so support use it and get your company details for your identity check without any further questions.',
    show_hide_amount: 'Suppose, The user wants to hide or show the financial values of the dashboard they can use this option. When this option is off, it will show all values on the dashboard as *.',
    add_remove_quick_link: 'Click here for add/remove to quick links',
    link_purpose: 'Link Purpose',
    brs_action_title: 'Suppose, user want to bind Payment / Receipt along with particular Bills / Invoices, then use this option.It will update bill / invoice status (Received / Partially received etc. as per amount posted against bill/invoice.)',
    current_assets: 'Current Assets Record The Assets That Do Not Belong Either To Bank Accounts Or To Cash-In-Hand,Deposit, Loans And Adv, Stock, Sundry Debtors Or  Sub-Groups.',
    bank_account: 'Current Account, Savings Account, Short Term Deposit Accounts And So On',
    cash_in_hand: 'Automatically Creates Cash A / C In This Group. You Can Open More Than One Cash Account, If Necessary.',
    deposites: 'Deposits Contain Fixed Deposits, Security Deposits Or Any Deposit Made By The Company (Not Received By The Company, Which Is A Liability).',
    loans_advances: 'This Records All Loans Given By The Company And Advances Of A Non-Trading Nature (Example: Advance Against Salaries) Or Even For Purchase Of Fixed Assets. We Do Not Recommend You To Open Advances To Suppliers’ Account Under This Group.',
    stock_in_hand: 'This Group Contains Accounts Like Raw Materials, Work-In-Progress And Finished Goods. The Balance Control Depends On Whether You Have Selected Maintenance Of Stock Option While Creating The Company.',
    sundry_debtors: 'For Customer Accounts',
    fixed_assets: 'Fixed assets are physical or tangible items that a company owns and uses in its business operations to provide services and goods to its customers and help drive income. These assets, which are often equipment or property, provide the owner long-term financial benefits.',
    investments: 'Group Your Investment Accounts Like Investment In Shares, Bonds, Govt. Securities, Long Term Bank Deposit Accounts And So On. This Allows You To View The Total Investments Made By The Company.',
    misc_expenses: 'This Group Is Typically Used For Legal Disclosure Requirements Such As Schedule Vi Of The Indian Companies Act. It Should Hold Incorporation And Pre-Operative Expenses. Companies Would Write Off A Permissible Portion Of The Account Every Year. A Balance Remains To An Extent That Cannot Be Written Off In Profit & Loss Account. Tally.Erp 9 Does Not Show Loss, Carried Forward In The Profit & Loss Account, Under This Group. The Profit & Loss Account Balance Is Displayed Separately In The Balance Sheet.',
    capital_account: "This Records The Capital And Reserves Of The Company. The Ledgers That Belong To Capital Accounts Are Share Capital, Partners' Capital A / C, Proprietor's Capital Account And So On.",
    reserves_surplus: 'Reserves are the funds earmarked for a definite reason or purpose that the business plans to use in the future and surplus is the profit of a business.',
    current_liabilities: 'Current liabilities (also called short-term liabilities) are debts a company must pay within a normal operating cycle, usually less than 12 months (as opposed to long-term liabilities, which are payable beyond 12 months). Paying off current liabilities is mandatory.',
    provisions: 'Accounts Like Provision For Taxation, Provision For Depreciation And So On Are Recorded Under Provisions.',
    sundry_creditors: 'For Vendor Accounts',
    loans: 'Loan Liabilities means all indebtedness and obligations (including all accrued and unpaid interest, principal, penalties, other fees, expense reimbursements and indemnities) owed to Lender by Borrower pursuant to the Loan Documents, but expressly excluding the Reserved Claims.',
    bank_od_acc: 'Provides You With Distinct Types Of Bank Accounts',
    secured_loans: 'Term Loans Or Other Long/Medium Term Loans, Which Are Obtained Against Security Of Some Asset. Does Not Verify The Existence Of The Security. Typical Accounts Are Debentures, Term Loans, And So On.',
    unsecured_loans: 'Loans Obtained Without Any Security. Example: Loans From Directors/Partners Or Outside Parties',
    sales_account_partA: 'You Can Classify Your Sales Accounts Based On Tax Slabs Or Type Of Sales. This Also Becomes A Simple Mechanism For Preparation Of Tax Returns. For Example,',
    sales_account_partB: 'Now Under Domestic Sales Open The Following Ledgers:',
    sales_account_partC: 'You Can Even Open An Account As Sales Returns Under The Group Domestic Sales To View Your Net Sales After Returns (Or The Returns May Be Directly Passed Through Journal Against The Specific Sales Account).',
    direct_incomes_partA: 'These Are Non-Trade Income Accounts That Affect Gross Profit. All Trade Income Accounts Fall Under Sales Accounts. You May Also Use This Group For Accounts Like Servicing, Contract Charges That Follow Sales Of Equipment.',
    direct_incomes_partB: 'For A Professional Services Company, You May Not Use Sales Account Group At All. Instead, Open Accounts Like Professional Fees Under This Group.',
    indirect_incomes: 'These Are Miscellaneous Non-Sale Income Accounts. Example: Rent Received And Interest Received.',
    purchase_account: 'This Is Similar To Sales Accounts, Except For The Type Of Transactions.',
    direct_expenses: 'These Are Manufacturing Or Direct Trading Expenses. These Accounts Determine The Gross Profit Of The Company.',
    indirect_expenses: 'All Administrative, Selling Or Non - Direct Expenses.',
    suspense_acc: 'In Modern Accounting, Many Large Corporations Use A Suspense Ledger To Track The Money Paid Or Recovered, The Nature Of Which Is Not Yet Known. The Most Common Example Is Money Paid For Traveling Advance Whose Details Will Be Known Only Upon Submission Of The Traveling Allowance Bill. Some Companies May Prefer To Open Such Accounts Under Suspense Account.',
    duties_taxes: 'Duties And Taxes Contain All Tax Accounts Like Vat, Cenvat, Excise, Gst ,Sales And Other Trade Taxes And The Total Liability (Or Asset In Case Of Advances Paid) And The Break-Up Of Individual Items',
    legal_name: 'A legal name is a name that is registered at the time of incorporation of a company or business. The legal name is generally the name of the company on the PAN. ',
    enable_credit_limit: 'User can define Credit limit for customer wise based on outstanding amount and no. of due invoices. User get notification when credit limit reached for selected customer. So either no. of due invoice or outstanding amount for credit limit need to set over here.',
    account_custom_field: 'Note: If you add any custom field here, it will be shown after the account address detail in sales invoice print header.',
    account_status: 'User can set account status as Active / Inactive using this option. e.g. Suppose this option is marked as Inactive, it will not display in any modules like Sales, Purchase, GST voucher and Reports.',
    base_sales_price: 'Rates behave exclusive or Inclusive based on settings. If you want to change it to Inclusive or exclusive, go to settings > invoice settings > item rate calculation based on.',
    item_status: 'User can set item status as Active / Inactive using this option. e.g. Suppose this option is marked as Inactive, it will not display in any modules like Sales, Purchase, GST voucher and Reports.',
    transport_status: 'User can set transporter status as Active / Inactive using this option. e.g. Suppose this option is marked as Inactive, it will not display in any modules like Sales, Purchase, GST voucher and Reports.',
    mfg_status: 'User can set mfg. company status as Active/Inactive using this option. e.g. Suppose this option is marked as Inactive, it will not display in any modules like Sales, Purchase, GST voucher and Reports.',
    left_right_arorw: 'Note : if you want to change any yes/no (radio button) option using keyboard then you have to use left or right arrow button as per your requirement.',
    edit: 'Edit',
    show_hide_column: 'Show/Hide columns',
    negative_qty_allowed: 'The option is useful when user have no stock in computer stock but physical stock is available for sale. User can mark this option as Yes to allow user to do sales entry. Later on user have to update the stock entry to manage proper figure of stock.',
    terms_days: 'Customer credit period in days',
    cancel: 'Cancel',
    save: 'Save',
    delete: 'Delete',
    create: 'Create',
    sales_history: 'View product wise sales history and further details. which helps you for sale.',
    place_of_supply: 'IGST will be applicable on the invoice. Suppose,  Selected billing accounts province is different in comparison to the Place of supply province.',
    upload_file_two_Mb: 'Note: The type of the upload file must be .jpeg/.jpg/.png/.pdf/.doc/.docx/.xlsx & size up to 2 MB.',
    bulk_invoice_update: 'User able to change Shipping details for multiple bills.',
    variation: 'Variation fields helpful for how much quantity need to adjust or wastage or consume. how variation calculated = Physical stk - Computer stk.',
    adj_qty: 'User can enter quantity as + (plus) or - (minus) and enter adjustment as per stock variation found.',
    wastage_qty: 'User can enter quantity for wastage as per variation found  and this entry is consider as always negative on report.',
    consume_qty: 'User can enter quantity for consume as per variation found and this entry is consider as always negative on report.',
    brs_mapping_header: 'System defined columns',
    brs_mapping_content: 'User can map their columns against these system defined columns. On basis of mapped column data value get transfer into accounting software and generate payment / receipt entries.',
    company_custom_field: 'Note: If you add any custom field here, it will be shown after the company address detail in sales invoice print header.',
    show_primary_email: 'The primary email address is shown here. Users are able to change it by clicking on the email preference link.',
    email_preference_one: 'What is Email Preferences?',
    email_preference_two: 'Email preferences are useful for email address verification and  set as primary email address for communication.',
    email_preference_three: 'User able to define multiple email addresses for communication and is able to keep it as primary from one of them. At a time only one email is kept as primary for company.',
    email_preference_four: 'Note: Once user set the primary address in preferences it will set company’s default email address here.',
    email_preference_five: 'Click on Email Preferences to go into settings for any change.',
    remove: 'Remove',
    manage_batch_wise: 'Users can use this option when managing products with batches.',
    mfg_exp_date: 'Settings that are useful when users want to change the date format and want to set its visibility. Also, Inputs are compulsory.',
    show_mrp: 'Settings that are useful when users want to set their visibility,  and inputs are compulsory.',
    manage_lot_wise: 'Users can use this option while managing products with lot wise.',
    purpose_of_setting: 'Purpose of Setting',
    sec_unit: 'Suppose user want to set secondary unit for any item. It will allow user to set user define conversion rate of unit.',
    set_default_unit: 'Suppose, User want to set default conversion for secondary unit, can set here as default. So on every item creation, conversion will show by default.',
    series_auto_roundoff: 'When creating entries in any transactions, as per series selection round-off requires On or Off in transaction.',
    gst_user_credentials: 'GST User credentials need to mention over here for GST level operations, So please enter GST user name in the below text box.',
    provide_lut_bond: 'LUT in GST: Full form/meaning is Letter of Undertaking. It is prescribed to be furnished in the form GST RFD 11 under rule 96 A, whereby the exporter declares that he or she will fulfill all the requirement that is prescribed under GST while exporting without making IGST payment.',
    eway_einvoice_credential: 'E-Way/E-invoice user credentials need to mention over here for e-way/e-invoice operations, So please enter the related user name in below text box.',
    credit_balance: 'Total available credit of Eway / Einvoice / 2A Reconcilation',
    three_credits: '3 credits for GSTR-2A monthly reconciliation',
    five_credits: '5 credits for GSTR-2A quarterly reconciliation',
    one_credit_eway: '1 credit for E-Way Generate/Cancel',
    one_credit_einvoice: '1 credit for E-Invoice Generate/Cancel',
    used_credit: 'Total used credit of Eway / Einvoice / 2A Reconcilation',
    set_as_default: 'Set as default',
    feature_req_upload_file: 'Allow only JPEG, JPG, PNG, GIF, BMP, AND PDF FILE FORMAT',
    new_master: 'Users are able to create a new master using the Insert key, It will work for Item master, Account, Books etc. This setting will work on all entry level modules.',
    delete_row: 'Users are able to delete a row using Delete key, it will work for those row which is focused. This setting will work on all entry level modules.',
    reset_date: 'Reset Date',
    clear_filter: 'Clear Filter',
    filter: 'Filter',
    not_allow_negative: 'Not allowed to transfer as new negative opening.',
    trans_negative: 'Suppose, you want to transfer this item as negative  new opening, you need to check mark allow negative stock checkbox. Once you checked mark this check box it will update item master as well.',
    diff_alias_name: 'If you want to differentiate company then add this alias name, it will see in company selection list in header.',
    valid_ten_min: 'OTP is valid for 10 minutes.',
    fax_format: 'fax format: +(country code)-(area code)-(fax number)',
    balance_transfer: 'Setting is useful for configure Balance transfer process as automatic or manual. when user make  any amount related change for customer / vendor. It will ask user to transfer automatically for next financial year, suppose user decline it transaction not allowed to save. In case of manual setting, notification show on dashboard, so user able to process it when they want.',
    stock_transfer: ' Setting is useful for configure Stock transfer process as automatic or manual. when user make  any stock related change. It will ask user to transfer automatically for next financial year, suppose user decline it transaction not allowed to save. In case of manual setting, notification show on dashboard, so user able to process it when they want.',
    upload_again: 'Upload again',
    selected_primary_acc: 'Selected primary account has been displayed on list!',
    limit_base: 'User can define limit based on no. of due invoices, e,g, suppose here you set 0 (zero) means no limit for due invoice.',
    outstanding_amount: 'User can define limit based on outstanding amount. e.g. suppose here you set 0 (Zero) means no limit for outstanding amount.',
    restrict_warning: 'Suppose, user want to notify warning and do not want to allow to create invoice, can choose this option.',
    allow_warning: 'Suppose, user want to notify warning and want to allow to create invoice, can choose this option.',
    default_printer: 'Check Default Printer',
    vendor_terms_days: 'Vendor credit period in days',
    transporter_doc_no: 'LR No.',
    transporter_doc_date: 'LR Date',
    eway_generated: 'E-Way Generated',
    einvoice_generated: 'E-Invoice generated',
    auto_adjust: 'Auto adjustment against opening balance of',
    get_help: 'Click here to help',
    stock_adj_mistake: 'There is some mistakes in stock adjustment entry, Please correct it then it allows you to save.',
    clear_item_qty: 'Clear item quantities.',
    munim_branding: 'What is Munim Branding?',
    munim_branding_via_docs: 'We the Munim doing our product branding via all your document prints / PDF.',
    show_munim_label: 'We are display Document Generate by Munim label in bottom of all documents (e.g. sales invoice, delivery challan, receipts and reports etc.)',
    remove_munim_branding: 'if you want to remove those branding label, you can click on Take Action button.',
    low_credit: 'Your purchase credit is low.',
    credit_consumption: 'How much credit consumption during operation?',
    create_batch_items: 'How to Create Batch items?',
    create_batch_items_step_1: 'Do batch-wise opening stock entry in item master.',
    create_batch_items_step_2: 'Do batch-wise purchase entry.',
    when_allowed: 'When Allowed?',
    when_not_allowed: 'When Not Allowed?',
    allow_manual_eway: 'Users can enter an e-Way bill number, in case when it is not generated using Munim’s eWay bill functionality.',
    not_allow_manual_eway: 'Suppose user had created e-Way bill using munim’s eWay bill functionality with sales invoice, in this case Users are not able to edit eWay bill details.',
    receipt_payment_purpose: 'Purpose of Receipt / Payment mapping',
    receipt_payment_mapping_detail: 'Suppose, any receipt / payment entry is matching with your imported bank statement, in this case user can link it from here and complete reconciliation for it. It will help user to reduce their manual work.',
    invoice_bill_purpose: 'Purpose of Bill to Bill mapping',
    invoice_bill_mapping_detail: 'Suppose, User want to set adjustment entry against bill, they can use this option in between reconciliation process and reduce task load and check outstanding against pending bills or invoices.',
    display_setting_tooltip_title: 'Purpose of Setting',
    display_setting_detail_1: 'This option is getting used in calculation and display. e.g. Users want to display up to 2 decimal for product quantity they can select option 2 from dropdown, and also it will applicable in calculation as well.',
    display_setting_detail_2: 'This option is getting used in calculation and display. e.g. Users want to display 2 decimal for Rate, they can select 2 from dropdown, and also it will applicable in calculation as well.',
    display_setting_detail_3: 'Amount format will applicable to entire software as per selected format. We are providing two types of display formats for it, with comma and without comma',
    shipping_location: 'User can define location name, so they can easily identify when making sales invoice.',
    invoice_title: 'Purpose of Change invoice type',
    example: 'For Example:',
    note: 'Note:',
    invoice_type_1: 'This option make invoice title dynamic as Tax invoice or Bill of supply.',
    tax_invoice: 'Tax invoice',
    invoice_type_2: 'Suppose, When user check mark this option, it will change title caption dynamic as per tax rate selection. Suppose invoice contain any tax items in sales invoice it will show invoice type as',
    invoice_type_3: 'and when user have added all items like Exempted or Nil tax in this case invoice title show as Bill of Supply.',
    invoice_type_4: 'You can able to set your own caption for Tax invoice and Bill of supply from header label settings.',
    more_help: 'Do you need more help?',
    left_right_arorw_navigation: 'If you want to navigate to the next or previous in view mode using the keyboard, use the left or right arrow keys as needed.',
    date_filter: 'You have already applied a date filter from the Filter, and at a time, only one date filter will work.',
    is_zero_stock_batch: 'This setting is useful only for batch items and applies to sales invoices only. Suppose, The batch item has zero stock and this setting is OFF, it will not show batch in the sales invoice batch list.'
}


export const svgString = {
    ChevronDown: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g opacity="0.5">
                           <path d="M6.59427 9.15866L3.09427 5.65866C2.9776 5.54199 2.91927 5.42533 2.91927 5.25033C2.91927 5.07533 2.9776 4.95866 3.09427 4.84199C3.3276 4.60866 3.6776 4.60866 3.91094 4.84199L7.0026 7.93366L10.0943 4.84199C10.3276 4.60866 10.6776 4.60866 10.9109 4.84199C11.1443 5.07533 11.1443 5.42533 10.9109 5.65866L7.41094 9.15866C7.1776 9.39199 6.8276 9.39199 6.59427 9.15866Z" fill="black" />
                           <mask id="mask0_20659_123963" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="2" y="4" width="10" height="6">
                               <path d="M6.59427 9.15866L3.09427 5.65866C2.9776 5.54199 2.91927 5.42533 2.91927 5.25033C2.91927 5.07533 2.9776 4.95866 3.09427 4.84199C3.3276 4.60866 3.6776 4.60866 3.91094 4.84199L7.0026 7.93366L10.0943 4.84199C10.3276 4.60866 10.6776 4.60866 10.9109 4.84199C11.1443 5.07533 11.1443 5.42533 10.9109 5.65866L7.41094 9.15866C7.1776 9.39199 6.8276 9.39199 6.59427 9.15866Z" fill="white" />
                           </mask>
                           <g mask="url(#mask0_20659_123963)">
                               <rect x="14" width="14" height="14" transform="rotate(90 14 0)" fill="black" />
                           </g>
                       </g>
                    </svg>`,
    ChevronRight: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                          <path d="M9.15573 7.40866L5.65573 10.9087C5.53906 11.0253 5.4224 11.0837 5.2474 11.0837C5.0724 11.0837 4.95573 11.0253 4.83906 10.9087C4.60573 10.6753 4.60573 10.3253 4.83906 10.092L7.93073 7.00033L4.83906 3.90866C4.60573 3.67533 4.60573 3.32533 4.83906 3.09199C5.0724 2.85866 5.4224 2.85866 5.65573 3.09199L9.15573 6.59199C9.38906 6.82533 9.38906 7.17533 9.15573 7.40866Z" fill="black" />
                          <mask id="mask0_18346_118276" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="4" y="2" width="6" height="10">
                              <path d="M9.15573 7.40866L5.65573 10.9087C5.53906 11.0253 5.4224 11.0837 5.2474 11.0837C5.0724 11.0837 4.95573 11.0253 4.83906 10.9087C4.60573 10.6753 4.60573 10.3253 4.83906 10.092L7.93073 7.00033L4.83906 3.90866C4.60573 3.67533 4.60573 3.32533 4.83906 3.09199C5.0724 2.85866 5.4224 2.85866 5.65573 3.09199L9.15573 6.59199C9.38906 6.82533 9.38906 7.17533 9.15573 7.40866Z" fill="white" />
                          </mask>
                          <g mask="url(#mask0_18346_118276)">
                              <rect width="14" height="14" fill="black" />
                          </g>
                      </g>
                    </svg>`
}